import classes from "./AnalogPage.module.css";
import headerImg from "../assets/img/analog/COVERimg/heroImg.webp";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { analogSeriesDataLoader } from "../util/analogDataLoader";

function AnalogPage() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const analogSeries = analogSeriesDataLoader();


  return (
    <>
      <section>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className={classes.introHeader}
        >
          <h4>
            It started with an old camera and a bit of curiosity, it developed
            into an obsession and a never ending search for that one unique
            perfectly timed shot.
          </h4>
          <div className={classes.imageContainerHeader}>
            <img src={headerImg} alt="mountain image black and white" />
          </div>
          <p>
            Below you’ll find a series of carefully chosen analog shots I made
            throughout the years. Some very old, others new, and some never
            shown on social media platforms. Every series and every shot has its
            special meaning engraved in time and space. All of these shots
            besides telling you a story also represent a moment in my
            professional and personal life. To me, analog is and always will be
            the truest form of photography, one chance, no excuses.
          </p>
        </motion.div>
      </section>
      <section>
        <ul className={classes.coverList}>
          
          {analogSeries.map((cover, index) => (
            <motion.li
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1.5 }}
              viewport={{ once: true }}
              key={index}
            >
              <div className={classes.coverContainer}>
                <h2>{cover.name}</h2>
                <p> {cover.description} </p>
                <Link
                  className={classes.analogSectionCenterLink}
                  to={`/analog/${cover.name}`}
                >
                  TAKE ME THERE
                </Link>
                <img src={cover.coverImage} alt={cover.name} />
              </div>
            </motion.li>
          ))}
        </ul>
      </section>
    </>
  );
}

export default AnalogPage;
