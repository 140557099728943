import { analogHiverImageLoader, analogLeGrandBlueImageLoader, analogLeGrandVertImageLoader, analogLesConnusImageLoader, analogLesInoubliablesImageLoader, analogNoirImageLoader, analogPremiereImageLoader, analogSeriesImageLoader, digitalBlackImageLoader, digitalConcreteImageLoader, digitalSavingMermaidsImageLoader, digitalSeriesImageLoader, digitalSnowImageLoader, digitalWavesImageLoader } from "./SeriesImageLoader";

export function allDataLoader() {

    const analogCoverImages = analogSeriesImageLoader();

    const noirSeriesImages = analogNoirImageLoader();
    const hiverSeriesImages = analogHiverImageLoader();
    const leGrandBlueSeriesImages = analogLeGrandBlueImageLoader();
    const leGrandVertSeriesImages = analogLeGrandVertImageLoader();
    const lesConnusSeriesImages = analogLesConnusImageLoader();
    const lesInoubliablesSeriesImages = analogLesInoubliablesImageLoader();
    const PremiereSeriesImages = analogPremiereImageLoader();
    const digitalCoverImages = digitalSeriesImageLoader();

    const snowSeriesImages = digitalSnowImageLoader();
    const wavesSeriesImages = digitalWavesImageLoader();
    const ConcreteSeriesImages = digitalConcreteImageLoader();
    const BlackSeriesImages = digitalBlackImageLoader();
    const SavingMermaidsSeriesImages = digitalSavingMermaidsImageLoader();


  const allSeries = [
    {
      name: "NOIR",
      description: "A black and white series",
      coverImage: analogCoverImages["Noir"],
      pictures: noirSeriesImages,
      text:(
        <>
          There is light and there is darkness <br/>
          Mostly I'm drawn to the darkness <br/>
          These stills represent the light <br/>
          I found in the darkness.
        </>
      ),
    },
    {
      name: "HIVER",
      description: "A series that thawd from winter",
      coverImage: analogCoverImages["Hiver"],
      pictures: hiverSeriesImages,
      text:(
        <>
          The seasons change and <br/>
          So the coldness draws nearer <br/>
          A change in scenery is due as <br/>
          I hear the white covered tops <br/>
          Calling me once again <br/>
        </>
      ),
    },
    {
      name: "LE GRAND BLEU",
      description: "A series that sprung from the vast ocean",
      coverImage: analogCoverImages["LeGrandBlue"],
      pictures: leGrandBlueSeriesImages,
      text:(
        <>
          Everything here is seemingly endless <br/>
          The horizon, the ocean, the cliffs, the beaches <br/>
          It marks a stark contrast with the <br/>
          Ever returning and ending <br/>
          Waves and summer.
        </>
      ),
    },
    {
      name: "LE GRAND VERT",
      description: "High up the mountains, close to the Gods",
      coverImage: analogCoverImages["LeGrandVert"],
      pictures: leGrandVertSeriesImages,
      text:(
        <>
          High up above <br/>
          Sceneries of utter imposing rawness <br/>
          Majestic and green, High and cold <br/>
          There's a sense of pureness here <br/>
          Not te be found elsewhere <br/>
          We are in the presence  <br/>
          Of something <br/>
          Beyond
        </>
      ),
    },
    {
      name: "LES CONNUS",
      description: "People I encountered, people I cherish",
      coverImage: analogCoverImages["LesConnus"],
      pictures: lesConnusSeriesImages,
      text:(
        <>
          No matter how breathtaking <br/>
          No matter how far you go <br/>
          Forget the waves and nevermind the slopes <br/>
          In the end <br/>
          It'll always be the people <br/>
          That truly matter
        </>
      ),
    },
    {
        name: "LES INOUBLIABLES",
      description: "Seemingly ordinary moments",
      coverImage: analogCoverImages["LesInoubliables"],
      pictures: lesInoubliablesSeriesImages,
      text:(
        <>
          There is something about <br/>
          The ordinary fleeting scenes  <br/>
          Found inbetween living and being lived<br/>
          If only we could stop for a moment <br/>
          And capture these rarities <br/>
          More often <br/>
        </>
      ),
    },
    {
      name: "PREMIERE",
      description: "The first shot of the roll",
      coverImage: analogCoverImages["Premiere"],
      pictures: PremiereSeriesImages,
      text:(
        <>
          You take your chance <br/>
          You take a gamble <br/>
          Life is but a roll of the dice <br/>
          Just like that first shot <br/>
          Of the roll is <br/>
        </>
      ),
    },
    {
      name: "SNOW",
      description: "winter sport extremities and shenanigans",
      coverImage: digitalCoverImages["Snow"],
      pictures: snowSeriesImages,
      text:(
        <>
          You hear the snow cracking <br/>
          Your speed makes the cold cut your face <br/>
          Utter focus, a sense of lightness befalls you<br/>
          A space between adrenaline and sudden death forms <br/>
          There's no room for mistakes, not here <br/>
          And this is exactly <br/>
          where you crave to be <br/>
        </>
      ),
    },
    {
        name: "WAVES",
        description: "The ocean's energy harvested",
        coverImage: digitalCoverImages["Waves"],
        pictures: wavesSeriesImages,
        text:(
          <>
            You never get used to the beauty of a wave<br/>
            It's shape, form, speed, all distintively unique <br/>
            Requiering decades of study to perfect the art<br/>
            Of letting one carry you to a place<br/>
            That is seemingly <br/>
            Out of this world
          </>
        ),
      },
      {
        name: "CONCRETE",
        description: "Without the mountains or oceans",
        coverImage: digitalCoverImages["Concrete"],
        pictures: ConcreteSeriesImages,
        text:(
          <>
            When the ocean is resting <br/>
            And the mountains are green <br/>
            You might find me somewhere inbetween <br/>
            Trying to mimic the feeling
            Of a place that isn't here
          </>
        ),
      },
      {
        name: "BLACK",
        description: "a digital black and white series",
        coverImage: digitalCoverImages["Black"],
        pictures: BlackSeriesImages,
        text:(
          <>
            There is light and there is darkness <br/>
            Mostly I'm drawn to the darkness <br/>
            These stills represent the light <br/>
            I found in the darkness
          </>
        ),
      },
      {
        name: "SAVING MERMAIDS",
        description: "A shoot for the Saving Mermaids brand",
        coverImage: SavingMermaidsSeriesImages[7],
        pictures: SavingMermaidsSeriesImages,
        text:(
          <>
            Saving mermaids is all about <br/>
            Preserving and respecting our oceans <br/>
            Sometimes the most simple solutions are the most efficient ones <br/>
            A durable hand crafted rope that carries a sustainable bottle <br/>
             
            <a href="https://www.instagram.com/savingmermaids/">Join us</a> and help us preserve our oceans <br/>
          </>
        ),
      },
  ];

  return allSeries;
}
