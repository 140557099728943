import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RootLayout from "./pages/Root";
import HomePage from "./pages/HomePage";
import AnalogPage from "./pages/AnalogPage";
import DigitalPage from "./pages/DigitalPage";
import ABoutPage from "./pages/AboutPage";
import AnalogDetailPage from "./pages/AnalogDetailPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    id: 'root',
    children: [
      {
        path: "",
        element: <HomePage />,
      },
      {
        path: "/analog",
        element: <AnalogPage />,
      },
      {
        path: "/analog/:name",
        element: <AnalogDetailPage />,
      },
      {
        path: "/digital",
        element: <DigitalPage />,
      },
      {
        path: "/digital/:name",
        element: <AnalogDetailPage />,
      },
      {
        path: "/about",
        element: <ABoutPage />,
      },
    ],
  },
]);

function App() {

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
