import { useEffect, useState } from "react";
import classes from "./AnalogItem.module.css";
import Modal from "./Modal";
import { motion } from "framer-motion";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, useLocation } from "react-router-dom";
import { allDataLoader } from "../util/allDataLoader";

export default function AnalogItem({ series }) {
  const location = useLocation();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, [location.pathname]);
  const analogSeries = allDataLoader();

  const [selectedImage, setSelectedImage] = useState(null);
  const [showModal, setShowModal] = useState(false);

  function handleImageClick(image) {
    setSelectedImage(image);
    setShowModal(true);
  }

  function closeModal() {
    setShowModal(false);
    setSelectedImage(null);
  }

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2.5,
      partialVisibilityGutter: 40 // this is needed to tell the amount of px that should be visible.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1.5,
      partialVisibilityGutter: 30 // this is needed to tell the amount of px that should be visible.
    }
  };
  return (
    <>
      <section className={classes.introHeader}>
        <h4>{series.name} SERIES</h4>
        <div className={classes.imageContainerHeader}>
          <img src={series.coverImage} alt={series.name} />
        </div>
        <p>{series.text}</p>
      </section>
      <section className={classes.gallery}>
        {series.pictures.map((image, index) => (
          <motion.figure
            key={index}
            className={`${classes.galleryItem} ${
              classes[`galleryItem${index}`]
            }`}
            onClick={() => handleImageClick(image)}
            initial={{ scale: 0.8 }}
            whileInView={{ scale: 1 }}
            transition={{ duration: 0.4 }}
            viewport={{ once: true }}
          >
            <img src={image} alt={`picture`} />
          </motion.figure>
        ))}
      </section>

      {showModal && selectedImage && (
        <Modal image={selectedImage} closeModal={closeModal} />
      )}
      <h4 className={classes.seeMore}>MORE SERIES</h4>
      <Carousel
      className={classes.carousel}
        swipeable={true}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        keyBoardControl={true}
        // centerMode={true}
        customTransition="transform 500ms ease-in-out"
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        itemClass="carousel-item-padding-40-px"
      >
        {analogSeries.map((cover) => (
          
          <div className={classes.coverContainer}>
            <div className={classes.coverContainerTitleDiv}>
              <p>{cover.name}</p>    
              <h2>{cover.description}</h2>
              <Link
            className={classes.analogSectionCenterLink}
            to={`/analog/${cover.name}`}
          >SEE CASE STUDY</Link>
            </div>
          <img src={cover.coverImage} alt={cover.name} />
        </div>
        
        ))}
      </Carousel>
      
    </>
  );
}
