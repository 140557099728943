import { useEffect } from "react";
import { useParams } from "react-router-dom";
import AnalogItem from "../components/AnalogItem";
import { allDataLoader } from "../util/allDataLoader";

function AnalogDetailPage() {
  const { name } = useParams();
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const allSeries = allDataLoader()
  const selectedSeries = allSeries.find(series => series.name === name);


  return (
    <>
      <AnalogItem series={selectedSeries} />
    </>
  );
}

export default AnalogDetailPage;
