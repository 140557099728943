import LandingPage from "../components/LandingPage";
import { Helmet } from 'react-helmet';
function HomePage() {

  
  return (
    <>
    <Helmet>
        <title>Gauthier Tampere</title>
        <meta name="description" content="Sports and lifestyle photographer - analog and digital" />
        <meta name="keywords" content="photography, analog, digital, camera, snow, ski, waves, ocean, surfing, skating, art" />
        <meta name="author" content="NoTocarProductions" />
        <meta property="og:title" content="Gauthier Tampere" />
        <meta property="og:description" content="Sports and lifestyle photographer - analog and digital" />
        <meta property="og:url" content="https://GauthierTampere.com/" />
        <meta name="twitter:title" content="Gauthier Tampere" />
        <meta name="twitter:description" content="Sports and lifestyle photographer - analog and digital" />
      </Helmet>
      <LandingPage />
    </>
  );
}

export default HomePage;

