import { analogHiverImageLoader, analogLeGrandBlueImageLoader, analogLeGrandVertImageLoader, analogLesConnusImageLoader, analogLesInoubliablesImageLoader, analogNoirImageLoader, analogPremiereImageLoader, analogSeriesImageLoader } from "./SeriesImageLoader";

export function analogSeriesDataLoader() {

    const analogCoverImages = analogSeriesImageLoader();

    const noirSeriesImages = analogNoirImageLoader();
    const hiverSeriesImages = analogHiverImageLoader();
    const leGrandBlueSeriesImages = analogLeGrandBlueImageLoader();
    const leGrandVertSeriesImages = analogLeGrandVertImageLoader();
    const lesConnusSeriesImages = analogLesConnusImageLoader();
    const lesInoubliablesSeriesImages = analogLesInoubliablesImageLoader();
    const PremiereSeriesImages = analogPremiereImageLoader();

    

  const analogSeries = [
    {
      name: "NOIR",
      description: "A black and white series",
      coverImage: analogCoverImages["Noir"],
      pictures: noirSeriesImages,
      text:(
        <>
          There is light and there is darkness <br/>
          Mostly I'm drawn to the darkness. <br/>
          These stills represent the light <br/>
          I found in the darkness.
        </>
      ),
    },
    {
      name: "HIVER",
      description: "A series that thawd from winter",
      coverImage: analogCoverImages["Hiver"],
      pictures: hiverSeriesImages,
      text:(
        <>
          The seasons change and <br/>
          so the coldness draws nearer. <br/>
          A change in scenery is due as <br/>
          I hear the white covered tops <br/>
          calling me once again. <br/>
        </>
      ),
    },
    {
      name: "LE GRAND BLEU",
      description: "A series that sprung from the vast ocean",
      coverImage: analogCoverImages["LeGrandBlue"],
      pictures: leGrandBlueSeriesImages,
      text:(
        <>
          Everything here is seemingly endless <br/>
          The horizon, the ocean, the cliffs, the beaches <br/>
          It marks a stark contrast with the <br/>
          ever returning and ending <br/>
          waves and summer.
        </>
      ),
    },
    {
      name: "LE GRAND VERT",
      description: "High up the mountains, close to the Gods",
      coverImage: analogCoverImages["LeGrandVert"],
      pictures: leGrandVertSeriesImages,
      text:(
        <>
          The house of the Gods are near <br/>
          Sceneries of utter imposing rawness <br/>
          Majestic and green, High and cold <br/>
          There's a sense of pureness here <br/>
          Not te be found elsewhere <br/>
          we are in the presence  <br/>
          of something <br/>
          beyond
        </>
      ),
    },
    {
      name: "LES CONNUS",
      description: "People I encountered, people I cherish",
      coverImage: analogCoverImages["LesConnus"],
      pictures: lesConnusSeriesImages,
      text:(
        <>
          No matter how breathtaking <br/>
          No matter how far you go <br/>
          Forget the waves and nevermind the slopes <br/>
          In the end <br/>
          It'll always be the people <br/>
          That truly matter
        </>
      ),
    },
    {
        name: "LES INOUBLIABLES",
      description: "My unforgettable seemlessly ordinary moments",
      coverImage: analogCoverImages["LesInoubliables"],
      pictures: lesInoubliablesSeriesImages,
      text:(
        <>
          There is something about <br/>
          The ordinary fleeting scenes  <br/>
          found inbetween living and being lived<br/>
          If only we could stop for a moment <br/>
          and capture these rarities <br/>
          more often <br/>
        </>
      ),
    },
    {
      name: "PREMIERE",
      description: "The first shot of the roll",
      coverImage: analogCoverImages["Premiere"],
      pictures: PremiereSeriesImages,
      text:(
        <>
          You take your chance, <br/>
          You take a gamble <br/>
          Life is but a roll of the dice <br/>
          Just like that first shot <br/>
          of the roll is <br/>
        </>
      ),
    },
  ];

  return analogSeries;
}
