import classes from './Footer.module.css';

function Footer() {
    return (
        <footer>
        <div className={classes.footerContainer}>
            <div>
                <p>@Copyright GAUTHIER TAMPERE 2023</p>
            </div>
            <div className={classes.footerContainerCenter}>
                    <h4>LET US WORK TOGETHER</h4>
                
                    <p>GAUTHIERTAMPERE@HOTMAIL.COM</p>
                
                <div className={classes.footerContainerCenterChild}>
                    <a href="https://casacalea.com/blogs/artistblog/gauthier-tampere">CASACALEA</a>
                    <a href="https://instagram.com/gauthegoat">INSTAGRAM</a>
                    <a href="https://www.facebook.com/gauthier.tampere">FACEBOOK</a>
                </div>
            </div>
            <div>
                <p>Design & dev - <a href="https://NoTocarProductions.com">NoTocarProductions</a></p>
            </div>
        </div>
    </footer>
    )
}

export default Footer;