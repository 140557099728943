//ANALOG
import NoirImage from "../assets/img/analog/COVERimg/Noir.webp"
import HiverImage from "../assets/img/analog/COVERimg/Hiver.webp"
import LeGrandBlueImage from "../assets/img/analog/COVERimg/LeGrandBlue.webp"
import LeGrandVertImage from "../assets/img/analog/COVERimg/LeGrandVert.webp"
import LesConnusImage from "../assets/img/analog/COVERimg/LesConnus.webp"
import LesInoubliablesImage from "../assets/img/analog/COVERimg/LesInoubliables.webp"
import PremiereImage from "../assets/img/analog/COVERimg/Premiere.webp"

//DIGITAL
import BlackImage from "../assets/img/digital/COVERimg/black.webp"
import ConcreteImage from "../assets/img/digital/COVERimg/concrete.webp"
import ImmersedImage from "../assets/img/digital/COVERimg/immersed.webp"
import SnowImage from "../assets/img/digital/COVERimg/snow.webp"
import WavesImage from "../assets/img/digital/COVERimg/waves.webp"
import OceanImage from "../assets/img/digital/COVERimg/ocean.webp"


//ANALOG NOIR   
import one from "../assets/img/analog/NOIR SERIES/one.webp"
import two from "../assets/img/analog/NOIR SERIES/two.webp"
import three from "../assets/img/analog/NOIR SERIES/three.webp"
import four from "../assets/img/analog/NOIR SERIES/four.webp"
import five from "../assets/img/analog/NOIR SERIES/five.webp"
import six from "../assets/img/analog/NOIR SERIES/six.webp"
import seven from "../assets/img/analog/NOIR SERIES/seven.webp"
import eight from "../assets/img/analog/NOIR SERIES/eight.webp"

//ANALOG HIVER
import H_one from "../assets/img/analog/HIVER SERIES/one.webp"
import H_two from "../assets/img/analog/HIVER SERIES/two.webp"
import H_three from "../assets/img/analog/HIVER SERIES/three.webp"
import H_four from "../assets/img/analog/HIVER SERIES/four.webp"
import H_five from "../assets/img/analog/HIVER SERIES/five.webp"
import H_six from "../assets/img/analog/HIVER SERIES/six.webp"
import H_seven from "../assets/img/analog/HIVER SERIES/seven.webp"
import H_eight from "../assets/img/analog/HIVER SERIES/eight.webp"


//ANALOG Le Grand Blue
import B_one from "../assets/img/analog/LEGRANDBLUE/one.webp"
import B_two from "../assets/img/analog/LEGRANDBLUE/two.webp"
import B_three from "../assets/img/analog/LEGRANDBLUE/three.webp"
import B_four from "../assets/img/analog/LEGRANDBLUE/four.webp"
import B_five from "../assets/img/analog/LEGRANDBLUE/five.webp"
import B_six from "../assets/img/analog/LEGRANDBLUE/six.webp"
import B_seven from "../assets/img/analog/LEGRANDBLUE/seven.webp"
import B_eight from "../assets/img/analog/LEGRANDBLUE/eight.webp"

//ANALOG Le Grand Vert
import V_one from "../assets/img/analog/LEGRANDVERT/one.webp"
import V_two from "../assets/img/analog/LEGRANDVERT/two.webp"
import V_three from "../assets/img/analog/LEGRANDVERT/three.webp"
import V_four from "../assets/img/analog/LEGRANDVERT/four.webp"
import V_five from "../assets/img/analog/LEGRANDVERT/five.webp"
import V_six from "../assets/img/analog/LEGRANDVERT/six.webp"
import V_seven from "../assets/img/analog/LEGRANDVERT/seven.webp"
import V_eight from "../assets/img/analog/LEGRANDVERT/eight.webp"

//ANALOG Les Connus
import C_one from "../assets/img/analog/LES CONNUS/one.webp"
import C_two from "../assets/img/analog/LES CONNUS/two.webp"
import C_three from "../assets/img/analog/LES CONNUS/three.webp"
import C_four from "../assets/img/analog/LES CONNUS/four.webp"
import C_five from "../assets/img/analog/LES CONNUS/five.webp"
import C_six from "../assets/img/analog/LES CONNUS/six.webp"
import C_seven from "../assets/img/analog/LES CONNUS/seven.webp"
import C_eight from "../assets/img/analog/LES CONNUS/eight.webp"


//ANALOG Les Inoubliables
import I_one from "../assets/img/analog/LES INOUBLIABLES SERIES/one.webp"
import I_two from "../assets/img/analog/LES INOUBLIABLES SERIES/two.webp"
import I_three from "../assets/img/analog/LES INOUBLIABLES SERIES/three.webp"
import I_four from "../assets/img/analog/LES INOUBLIABLES SERIES/four.webp"
import I_five from "../assets/img/analog/LES INOUBLIABLES SERIES/five.webp"
import I_six from "../assets/img/analog/LES INOUBLIABLES SERIES/six.webp"
import I_seven from "../assets/img/analog/LES INOUBLIABLES SERIES/seven.webp"
import I_eight from "../assets/img/analog/LES INOUBLIABLES SERIES/eight.webp"

//ANALOG Premiere
import P_one from "../assets/img/analog/PREMIERE SERIES/one.webp"
import P_two from "../assets/img/analog/PREMIERE SERIES/two.webp"
import P_three from "../assets/img/analog/PREMIERE SERIES/three.webp"
import P_four from "../assets/img/analog/PREMIERE SERIES/four.webp"
import P_five from "../assets/img/analog/PREMIERE SERIES/five.webp"
import P_six from "../assets/img/analog/PREMIERE SERIES/six.webp"


//DIGITAL BLACK
import DB_one from "../assets/img/digital/BLACK/one.webp"
import DB_two from "../assets/img/digital/BLACK/two.webp"
import DB_three from "../assets/img/digital/BLACK/three.webp"
import DB_four from "../assets/img/digital/BLACK/four.webp"
import DB_five from "../assets/img/digital/BLACK/five.webp"
import DB_six from "../assets/img/digital/BLACK/six.webp"
import DB_seven from "../assets/img/digital/BLACK/seven.webp"
import DB_eight from "../assets/img/digital/BLACK/eight.webp"



//DIGITAL CONCRETE
import DC_one from "../assets/img/digital/CONCRETE/one.webp"
import DC_two from "../assets/img/digital/CONCRETE/two.webp"
import DC_three from "../assets/img/digital/CONCRETE/three.webp"
import DC_four from "../assets/img/digital/CONCRETE/four.webp"
import DC_five from "../assets/img/digital/CONCRETE/five.webp"
import DC_six from "../assets/img/digital/CONCRETE/six.webp"
import DC_seven from "../assets/img/digital/CONCRETE/seven.webp"
import DC_eight from "../assets/img/digital/CONCRETE/eight.webp"


//DIGITAL SNOW
import DS_one from "../assets/img/digital/SNOW/one.webp"
import DS_two from "../assets/img/digital/SNOW/two.webp"
import DS_three from "../assets/img/digital/SNOW/three.webp"
import DS_four from "../assets/img/digital/SNOW/four.webp"
import DS_five from "../assets/img/digital/SNOW/five.webp"
import DS_six from "../assets/img/digital/SNOW/six.webp"
import DS_seven from "../assets/img/digital/SNOW/seven.webp"


//DIGITAL WAVES
import DW_one from "../assets/img/digital/WAVES/one.webp"
import DW_two from "../assets/img/digital/WAVES/two.webp"
import DW_three from "../assets/img/digital/WAVES/three.webp"
import DW_four from "../assets/img/digital/WAVES/four.webp"
import DW_five from "../assets/img/digital/WAVES/five.webp"
import DW_six from "../assets/img/digital/WAVES/six.webp"
import DW_seven from "../assets/img/digital/WAVES/seven.webp"
import DW_eight from "../assets/img/digital/WAVES/eight.webp"

//SAVING MERMAIDS 
import SM_one from "../assets/img/digital/SAVING MERMAIDS/one.webp"
import SM_two from "../assets/img/digital/SAVING MERMAIDS/two.webp"
import SM_three from "../assets/img/digital/SAVING MERMAIDS/three.webp"
import SM_four from "../assets/img/digital/SAVING MERMAIDS/four.webp"
import SM_five from "../assets/img/digital/SAVING MERMAIDS/five.webp"
import SM_six from "../assets/img/digital/SAVING MERMAIDS/six.webp"
import SM_seven from "../assets/img/digital/SAVING MERMAIDS/seven.webp"
import SM_eight from "../assets/img/digital/SAVING MERMAIDS/eight.webp"



//GAUT
import Gaut from "../assets/img/analog/NOIR SERIES/gaut.webp";


export function analogSeriesImageLoader() {
    const analogImagesList = {
        "Noir": NoirImage,
        "Hiver": HiverImage,
        "LeGrandBlue": LeGrandBlueImage,
        "LeGrandVert": LeGrandVertImage,
        "LesConnus": LesConnusImage,
        "LesInoubliables": LesInoubliablesImage,
        "Premiere": PremiereImage
    };
    
    return analogImagesList;
}

export function digitalSeriesImageLoader() {
    const digitalImagesList = {
        "Black": BlackImage,
        "Concrete": ConcreteImage,
        "Immersed": ImmersedImage,
        "Snow": SnowImage,
        "Waves": WavesImage,
        "Ocean": OceanImage
    };
    
    return digitalImagesList;
}

    export function analogNoirImageLoader() {
        const NoirImagesList = {
            "one": one,
            "two": two,
            "three": three,
            "four": four,
            "five": five,
            "six": six,
            "seven": seven,
            "eight": eight,
        };
        
        const picturesArray = Object.values(NoirImagesList);
    
        return picturesArray;
}


export function analogHiverImageLoader() {
    const HiverImagesList = {
        "one": H_one,
        "two": H_two,
        "three": H_three,
        "four": H_four,
        "five": H_five,
        "six": H_six,
        "seven": H_seven,
        "eight": H_eight,
    };

    const picturesArray = Object.values(HiverImagesList);
    
    return picturesArray;
    
}


export function analogLeGrandBlueImageLoader() {
    const LeGrandBlueImagesList = {
        "one": B_one,
        "two": B_two,
        "three": B_three,
        "four": B_four,
        "five": B_five,
        "six": B_six,
        "seven": B_seven,
        "eight": B_eight,
    };
    
    const picturesArray = Object.values(LeGrandBlueImagesList);
    
    return picturesArray;
}

export function analogLeGrandVertImageLoader() {
    const LeGrandVertImagesList = {
        "one": V_one,
        "two": V_two,
        "three": V_three,
        "four": V_four,
        "five": V_five,
        "six": V_six,
        "seven": V_seven,
        "eight": V_eight,
    };
    
    const picturesArray = Object.values(LeGrandVertImagesList);
    
    return picturesArray;
}


export function analogLesConnusImageLoader() {
    const LesConnusImagesList = {
        "one": C_one,
        "two": C_two,
        "three": C_three,
        "four": C_four,
        "five": C_five,
        "six": C_six,
        "seven": C_seven,
        "eight": C_eight,
    };
    
    const picturesArray = Object.values(LesConnusImagesList);
    
    return picturesArray;
}


export function analogLesInoubliablesImageLoader() {
    const LesInoubliablesImagesList = {
        "one": I_one,
        "two": I_two,
        "three": I_three,
        "four": I_four,
        "five": I_five,
        "six": I_six,
        "seven": I_seven,
        "eight": I_eight,
    };
    
    const picturesArray = Object.values(LesInoubliablesImagesList);
    
    return picturesArray;
}

export function analogPremiereImageLoader() {
    const PremiereImagesList = {
        "one": P_one,
        "two": P_two,
        "three": P_three,
        "four": P_four,
        "five": P_five,
        "six": P_six,
    };
    
    const picturesArray = Object.values(PremiereImagesList);
    
    return picturesArray;
}


export function digitalBlackImageLoader() {
    const BlackImagesList = {
        "one": DB_one,
        "two": DB_two,
        "three": DB_three,
        "four": DB_four,
        "five": DB_five,
        "six": DB_six,
        "seven": DB_seven,
        "eight": DB_eight,
    };
    
    const picturesArray = Object.values(BlackImagesList);
    
    return picturesArray;
}


export function digitalConcreteImageLoader() {
    const ConcreteImagesList = {
        "one": DC_one,
        "two": DC_two,
        "three": DC_three,
        "four": DC_four,
        "five": DC_five,
        "six": DC_six,
        "seven": DC_seven,
        "eight": DC_eight,
    };
    
    const picturesArray = Object.values(ConcreteImagesList);
    
    return picturesArray;
}


export function digitalSnowImageLoader() {
    const SnowImagesList = {
        "one": DS_one,
        "two": DS_two,
        "three": DS_three,
        "four": DS_four,
        "five": DS_five,
        "six": DS_six,
        "seven": DS_seven,
    };
    
    const picturesArray = Object.values(SnowImagesList);
    
    return picturesArray;
}



export function digitalWavesImageLoader() {
    const WavesImagesList = {
        "one": DW_one,
        "two": DW_two,
        "three": DW_three,
        "four": DW_four,
        "five": DW_five,
        "six": DW_six,
        "seven": DW_seven,
        "eight": DW_eight,
    };
    
    const picturesArray = Object.values(WavesImagesList);
    
    return picturesArray;
}


export function digitalSavingMermaidsImageLoader() {
    const SavingMermaidsImagesList = {
        "one": SM_one,
        "two": SM_two,
        "three": SM_three,
        "four": SM_four,
        "five": SM_five,
        "six": SM_six,
        "seven": SM_seven,
        "eight": SM_eight,
    };
    
    const picturesArray = Object.values(SavingMermaidsImagesList);
    
    return picturesArray;
}

export function gautLoader() {
    const gaut = Gaut;

    return gaut;
}
