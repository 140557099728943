import { digitalBlackImageLoader, digitalConcreteImageLoader, digitalSavingMermaidsImageLoader, digitalSeriesImageLoader, digitalSnowImageLoader, digitalWavesImageLoader } from "./SeriesImageLoader";

export function digitalSeriesDataLoader() {

    const digitalCoverImages = digitalSeriesImageLoader();

    const snowSeriesImages = digitalSnowImageLoader();
    const wavesSeriesImages = digitalWavesImageLoader();
    const ConcreteSeriesImages = digitalConcreteImageLoader();
    const BlackSeriesImages = digitalBlackImageLoader();
    const SavingMermaidsSeriesImages = digitalSavingMermaidsImageLoader();


  const digitalSeries = [
    {
      name: "SNOW",
      description: "winter sport extremities and shenanigans",
      coverImage: digitalCoverImages["Snow"],
      pictures: snowSeriesImages,
      text:(
        <>
          You hear the snow cracking <br/>
          Your speed makes the cold cut your face. <br/>
          Utter focus, a sense of lightness befalls you<br/>
          A space between adrenaline and death forms <br/>
          There's no room for mistakes, not here <br/>
          And it's exactly here <br/>
          where you crave to be <br/>
        </>
      ),
    },
    {
        name: "WAVES",
        description: "The ocean's energy harvested",
        coverImage: digitalCoverImages["Waves"],
        pictures: wavesSeriesImages,
        text:(
          <>
            There is light and there is darkness <br/>
            Mostly I'm drawn to the darkness. <br/>
            These stills represent the light <br/>
            I found in the darkness.
          </>
        ),
      },
      {
        name: "CONCRETE",
        description: "Without the mountains or oceans",
        coverImage: digitalCoverImages["Concrete"],
        pictures: ConcreteSeriesImages,
        text:(
          <>
            There is light and there is darkness <br/>
            Mostly I'm drawn to the darkness. <br/>
            These stills represent the light <br/>
            I found in the darkness.
          </>
        ),
      },
      {
        name: "BLACK",
        description: "a digital black and white series",
        coverImage: digitalCoverImages["Black"],
        pictures: BlackSeriesImages,
        text:(
          <>
            There is light and there is darkness <br/>
            Mostly I'm drawn to the darkness. <br/>
            These stills represent the light <br/>
            I found in the darkness.
          </>
        ),
      },
      {
        name: "SAVING MERMAIDS",
        description: "A shoot for the Saving Mermaids brand",
        coverImage: SavingMermaidsSeriesImages[7],
        pictures: SavingMermaidsSeriesImages,
        text:(
          <>
            Saving mermaids is all about <br/>
            preserving and respecting our oceans. <br/>
            Sometimes the most simple solutions are the most efficient ones <br/>
            A durable hand crafted rope that carries a sustainable bottle <br/>
            Join us and help us preserve our oceans
          </>
        ),
      },
    ]

  return digitalSeries;
}

