import { useRouteLoaderData, NavLink, Form } from "react-router-dom";
import classes from "./MainNavigation.module.css";
import {motion} from 'framer-motion';


function MainNavigation() {
  return (
    <header className={classes.header}>
      <motion.nav
      initial={{opacity: 0, y:-200}}
      animate={{opacity: 1, y:0}}
      transition={{delay:0.8, duration:0.9}}
      >
        <ul className={classes.list}>
          <li className={classes.home}>
            <NavLink to="">Home</NavLink>
          </li>
          <li>
            <NavLink
              to="/analog"
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              Analog
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/digital"
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              Digital
            </NavLink>
              </li>
              <li>
            <NavLink
              to="/about"
              className={({ isActive }) =>
                isActive ? classes.active : undefined
              }
            >
              About
            </NavLink>
              </li>
        </ul>
      </motion.nav>
    </header>
  );
}

export default MainNavigation;
