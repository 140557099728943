import React from "react";
import classes from "./Modal.module.css";
import { motion } from "framer-motion";

const Modal = ({ image, closeModal }) => {
  const handleOverlayClick = (e) => {
    // Check if the click event target is the overlay itself, not the modal content
    if (e.target === e.currentTarget) {
      closeModal(); // Close the modal if clicked outside the modal content
    }
  };
  return (
    <motion.div
      className={classes.modalOverlay}
      onClick={handleOverlayClick}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ type: "spring", stiffness: 50, mass: 0.5 }}
      exit={{scale: 0 }}
    >
      <div className={classes.modalContent} onClick={handleOverlayClick}>
        <img
          src={image}
          alt="Full size"
          className={classes.modalImage}
          onClick={handleOverlayClick}
        />
        <button className={classes.closeButton} onClick={closeModal}>
          Close
        </button>
      </div>
    </motion.div>
  );
};

export default Modal;
