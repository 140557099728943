import { useEffect, useRef } from "react";
// import gautvideo from "../assets/img/digital/COVERimg/gautvideo.mp4";
import classes from "./LandingPage.module.css";
import { motion, useTime, useTransform } from "framer-motion";
import analogLeftImg from "../assets/img/analog/COVERimg/LesInoubliables.webp";
import analogRightImg from "../assets/img/analog/COVERimg/kabelbaan.webp";
import digitalRightImg from "../assets/img/digital/COVERimg/snow.webp";
import digitalLeftImg from "../assets/img/digital/COVERimg/black.webp";
import heroImg from "../assets/img/analog/COVERimg/LeGrandBlue.webp";
import { Link, NavLink } from "react-router-dom";
import { useMediaQuery } from "react-responsive"; // Import useMediaQuery hook

function LandingPage() {
  const videoRef = useRef(null);

  const isSmallerScreen = useMediaQuery({ maxWidth: 900, minWidth: 650 });
  const isExtraSmallScreen = useMediaQuery({ maxWidth: 600 });

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = 0.6;
    }
  }, []);

  const time = useTime();
  const loadingPageScale = useTransform(time, [0, 2000], [5, 1], {
    clamp: true,
  });

  const introDivTitleRef = useRef(null);

  function handleButtonClick() {
    introDivTitleRef.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <section>
        <motion.div
          initial={{ opacity: 0, scale: 0, y: 300 }}
          animate={{ opacity: 1, scale: 1, y: 0 }}
          transition={{ type: "spring", stiffness: 200, mass: 1.2 }}
          className={classes.videoContainer}
        >
          {/* <video
          ref={videoRef}
          className={classes.video}
          autoPlay
          loop
          muted
          controls={false}
        >
          <source src={gautvideo} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
          <motion.img
            src={heroImg}
            style={{ scale: loadingPageScale }}
          ></motion.img>
          <motion.div
            className={classes.textDiv}
            // style={{
            //   translateY: scrollYProgress,
            //   transition: {translateY: {duration: 0.5}}
            // }}
          >
            <h1 className={classes.title}>GAUTHIER TAMPERE </h1>
            <p className={classes.subTitle}>
              SPORTS & LIFESTYLE
              <br />
              PHOTOGRAPHER
            </p>
            <button className={classes.enterButton} onClick={handleButtonClick}>
              ENTER
            </button>
          </motion.div>
        </motion.div>

        <motion.h1
          initial={{ opacity: 0, scale: 0.1 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.5, duration: 1.5 }}
          viewport={{ once: true }}
          className={classes.sectionTitle}
          ref={introDivTitleRef}
        >
          ANALOG
        </motion.h1>
        <div className={classes.analogSection}>
          <motion.div
            initial={{
              opacity: 0,
              width: isExtraSmallScreen ? 0 : isSmallerScreen ? 0 : 800,
            }}
            whileInView={{
              opacity: 0.8,
              width: isSmallerScreen ? (isExtraSmallScreen ? 350 : 600) : 350,
            }}
            transition={{ delay: 0.5, duration: 1.5 }}
            viewport={{ once: true }}
            className={classes.analogSectionSub}
          >
            <img
              className={classes.analogSectionImg}
              src={analogLeftImg}
              alt="analogImage"
            />
            <h2 className={classes.analogSectionTitle}>
              <span className={classes.letterSpan}>A</span>
              <span className={classes.letterSpan}>N</span>
              <span className={classes.letterSpan}>A</span>
              <span className={classes.letterSpan}>L</span>
              <span className={classes.letterSpan}>O</span>
              <span className={classes.letterSpan}>G</span>
            </h2>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, scale: 0.001 }}
            whileInView={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.2, duration: 1.5 }}
            viewport={{ once: true }}
            className={classes.analogSectionCenter}
          >
            <p className={classes.analogSectionCenterText}>
              The analog series is a collection of photographs born out of my
              curiosity and affection for vintage cameras. Each series
              represents a part of me, be it a darker part or a lighter, more
              hopeful part. Each collection is named after a common element in
              each of the photographs. This can be a place on earth that fills
              me with joy, it can be people or even a general melancholic
              feeling I can’t seem to get rid of. Feel free to browse through
              the collections at will. I hope they’ll hit a similar note in you
              as they do with me.
            </p>
            <NavLink className={classes.analogSectionCenterLink} to="/analog">
              SEE COLLECTION
            </NavLink>
          </motion.div>
          <motion.div
            initial={{
              opacity: 0,
              width: isExtraSmallScreen ? 0 : isSmallerScreen ? 0 : 800,
            }}
            whileInView={{
              opacity: 0.8,
              width: isSmallerScreen ? (isExtraSmallScreen ? 350 : 600) : 350,
            }}
            transition={{ delay: 0.5, duration: 1.5 }}
            viewport={{ once: true }}
            className={classes.analogSectionSub}
          >
            <img
              className={classes.analogSectionImg}
              src={analogRightImg}
              alt="analogImage"
            />
            <h2 className={classes.analogSectionTitle}>
              <span className={classes.letterSpan}>S</span>
              <span className={classes.letterSpan}>E</span>
              <span className={classes.letterSpan}>R</span>
              <span className={classes.letterSpan}>I</span>
              <span className={classes.letterSpan}>E</span>
              <span className={classes.letterSpan}>S</span>
            </h2>
          </motion.div>
        </div>
      </section>
      <motion.h1
        initial={{ opacity: 0, scale: 0.1 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ delay: 0.5, duration: 1.5 }}
        viewport={{ once: true }}
        className={classes.sectionTitle}
      >
        DIGITAL
      </motion.h1>
      <div className={classes.analogSection}>
        <motion.div
          initial={{
            opacity: 0,
            width: isExtraSmallScreen ? 0 : isSmallerScreen ? 0 : 800,
          }}
          whileInView={{
            opacity: 0.8,
            width: isSmallerScreen ? (isExtraSmallScreen ? 350 : 600) : 350,
          }}
          transition={{ delay: 0.5, duration: 1.5 }}
          viewport={{ once: true }}
          className={classes.analogSectionSub}
        >
          <img
            className={`${classes.analogSectionImg} ${classes.digitalSectionImgRight}`}
            src={digitalRightImg}
            alt="digitalImage"
          />
          <h2
            className={`${classes.analogSectionTitle} ${classes.digitalSectionTitle}`}
          >
            <span className={classes.letterSpan}>S</span>
            <span className={classes.letterSpan}>E</span>
            <span className={classes.letterSpan}>R</span>
            <span className={classes.letterSpan}>I</span>
            <span className={classes.letterSpan}>E</span>
            <span className={classes.letterSpan}>S</span>
          </h2>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, scale: 0.001 }}
          whileInView={{ opacity: 1, scale: 1 }}
          transition={{ delay: 0.2, duration: 1.5 }}
          viewport={{ once: true }}
          className={classes.analogSectionCenter}
        >
          <p className={classes.analogSectionCenterText}>
            Born out of my addiction comes the reflection of it through a
            digital lens. The images in the digital series represent people
            doing what they love most, what I love most. They live fast, take
            risks, and never look back. It’s exactly that essence I try to
            capture with my digital series. Here too you’ll find the two
            opposing seasons, two extremities. From high in the mountains to
            deep in the oceans to somewhere in between.
          </p>
          <NavLink className={classes.analogSectionCenterLink} to="/digital">
            SEE COLLECTION
          </NavLink>
        </motion.div>
        <motion.div
          initial={{
            opacity: 0,
            width: isExtraSmallScreen ? 0 : isSmallerScreen ? 0 : 800,
          }}
          whileInView={{
            opacity: 0.8,
            width: isSmallerScreen ? (isExtraSmallScreen ? 350 : 600) : 350,
          }}
          transition={{ delay: 0.5, duration: 1.5 }}
          viewport={{ once: true }}
          className={classes.analogSectionSub}
        >
          <img
            className={classes.analogSectionImg}
            src={digitalLeftImg}
            alt="digitalImage"
          />
          <h2
            className={`${classes.analogSectionTitle} ${classes.digitalSectionTitle}`}
          >
            <span className={classes.letterSpan}>D</span>
            <span className={classes.letterSpan}>I</span>
            <span className={classes.letterSpan}>G</span>
            <span className={classes.letterSpan}>I</span>
            <span className={classes.letterSpan}>T</span>
            <span className={classes.letterSpan}>A</span>
            <span className={classes.letterSpan}>L</span>
          </h2>
        </motion.div>
      </div>
    </>
  );
}

export default LandingPage;
