import classes from "./AnalogPage.module.css";
import headerImg from "../assets/img/digital/COVERimg/ocean.webp";
import { motion } from "framer-motion";
import { NavLink } from "react-router-dom";
import { useEffect } from "react";
import { digitalSeriesDataLoader } from "../util/digitalDataLoader";

function AnalogPage() {
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);


  const digitalSeries = digitalSeriesDataLoader();

  return (
    <>
      <section>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
          className={classes.introHeader}
        >
          <h4>
            At the
            <br />
            intersection <br />
            of two passions,
            <br />
            you’ll find my eye.
          </h4>
          <div className={classes.imageContainerHeader}>
            <img src={headerImg} alt="mountain image black and white" />
          </div>
          <p>
            Right at the intersection of my two passions is where you’ll find my
            digital photography. As I’m an avid practitioner of extreme sports I
            absolutely love to capture these sports in all their glorious
            beauty. True craftmanship comes with the perfect timing and angle,
            combined with a deep knowledge of the sports photographed.
          </p>
        </motion.div>
      </section>
      <section>
        <ul>
          {digitalSeries.map((cover, index) => (
            <motion.li
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              transition={{ duration: 1.5 }}
              viewport={{ once: true }}
              key={index}
            >
              <div className={classes.coverContainer}>
                <h2>{cover.name}</h2>
                <p> {cover.description} </p>
                <NavLink
                  className={classes.analogSectionCenterLink}
                  to={`/digital/${cover.name}`}
                >
                  TAKE ME THERE
                </NavLink>
                <img src={cover.coverImage} alt={cover.name} />
              </div>
            </motion.li>
          ))}
        </ul>
      </section>
    </>
  );
}

export default AnalogPage;
