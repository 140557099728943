import React from "react";
import { motion, useViewportScroll, useTransform } from "framer-motion";
import classes from "./AboutPage.module.css";
import GautOne from "../assets/img/digital/GautOne.webp";
import GautTwo from "../assets/img/digital/GautTwo.webp";
import { NavLink } from "react-router-dom";

const images = [GautOne, GautTwo]; // Your image paths

const ImageSlider = () => {
  const { scrollY } = useViewportScroll();
  const translateY = useTransform(scrollY, [0, 300], [0, 200]);
  const opacity = useTransform(scrollY, [0, 300], [1, 0]);
  const opacityInverted = useTransform(scrollY, [0, 120], [0, 1]);

  return (
    <section>
      <div className={classes.mainAboutDiv}>
        <motion.h1 style={{ translateY, opacity }}>GAUTHIER TAMPERE</motion.h1>
        <div className={classes.imageContainer}>
          <motion.img
            src={images[0]}
            alt="image-0"
            className={classes.image}
            style={{ opacity: opacityInverted }}
          />
          <motion.img
            src={images[1]}
            alt="image-1"
            className={classes.image}
            style={{ opacity }}
          />
        </div>
        <motion.p
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ delay: 0.8, duration: 1.8 }}
          viewport={{ once: true }}
        >
          Gauthier <br /> comes from a small town in rural Belgium but has left
          his homestead ever since he bought his first Volkswagen T3. In summer, you’ll find
          him under the sun near the ocean in the south of Europe. In winter
          you’ll find him high up in the mountains in
          Switzerland. A similar duality is reflected in his art. Gauthier
          Switches back and forth between analog and digital photography. In the
          past years he developed a keen eye and deep knowledge of his craft.
          Thus allowing him to pick the right camera for the right moment. 
          Feel free to see for yourself.
        </motion.p>
      </div>
      <motion.div
        className={classes.workDiv}
        initial={{ opacity: 0, y: -200 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 1.5 }}
        viewport={{ once: true }}
      >
        <h1 className={classes.workDivTitle}>CAMERA'S</h1>
        <div>
          <p>Sony A7 Mark III</p>
          <p>Canon FTb</p>
          <p>Canon F1</p>
          <p>Kodak Retina</p>
          <p>Leica Minilux</p>
          <p>Ikoflex Ila</p>
        </div>
      </motion.div>
      <motion.div
        className={classes.workDiv}
        initial={{ opacity: 0, y: -200 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 1.5 }}
        viewport={{ once: true }}
      >
        <h1 className={classes.workDivTitle}>ANALOG</h1>
        <div>
          <NavLink to="/analog/NOIR">Noir</NavLink>
          <NavLink to="/analog/HIVER">Hiver</NavLink>
          <NavLink to="/analog/LE GRAND BLUE">Le Grand Blue</NavLink>
          <NavLink to="/analog/LE GRAND VERT">Le Grand Vert</NavLink>
          <NavLink to="/analog/LES CONNUS">Les Connus</NavLink>
          <NavLink to="/analog/LES INOUBLIABLES">Les Inoubliables</NavLink>
          <NavLink to="/analog/PREMIERE">Premiere</NavLink>
        </div>
      </motion.div>
      <motion.div
        className={classes.workDiv}
        initial={{ opacity: 0, y: -200 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 1.5 }}
        viewport={{ once: true }}
      >
        <h1 className={classes.workDivTitle}>DIGITAL</h1>
        <div>
          <NavLink to="/digital/SNOW">Snow</NavLink>
          <NavLink to="/digital/WAVES">Waves</NavLink>
          <NavLink to="/digital/CONCRETE">Concrete</NavLink>
          <NavLink to="/digital/BLACK">Black</NavLink>
          <NavLink to="/digital/SAVING MERMAIDS">Saving Mermaids</NavLink>
        </div>
      </motion.div>
      <motion.div
        className={classes.workDiv}
        initial={{ opacity: 0, y: -200 }}
        whileInView={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 1.5 }}
        viewport={{ once: true }}
      >
        <h1 className={classes.workDivTitle}>BUY PRINTS</h1>
        <div>
          <a href="https://casacalea.com/products/arrifana?variant=44531829080329">Arrifana</a>
          <a href="https://casacalea.com/products/cutback?variant=44531830849801">Cutback</a>
          <a href="https://casacalea.com/products/vanlife?variant=44531834913033/digital/CONCRETE">Vanlife</a>
          <a href="https://casacalea.com/products/plantbased?variant=44531838517513">Plantbased</a>
          <a href="https://casacalea.com/products/sunset-flight?variant=44531825377545">Sunset flight</a>
          <a href="https://casacalea.com/products/picos-de-europa?variant=44531841270025">Picos De Europa</a>
          <a href="https://casacalea.com/products/wipeout?variant=44531843432713">Wipeout</a>
          <a href="https://casacalea.com/products/beliche?variant=44531815973129">Beliche</a>
        </div>
      </motion.div>
    </section>
  );
};

export default ImageSlider;
